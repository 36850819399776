import { useState, useEffect } from 'react';
import { fetchTags, TagResponse } from '../api/cocktailApi';

const useTags = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadTags = async () => {
      setLoading(true);
      setError(null);
      try {
        const data: TagResponse = await fetchTags();
        setTags(data.tags);
      } catch (err) {
        setError('Fehler beim Laden der Tags');
      } finally {
        setLoading(false);
      }
    };

    loadTags();
  }, []);

  return { tags, loading, error };
};

export default useTags;
