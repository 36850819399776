import axios from 'axios';

const API_BASE_URL = 'https://cocktails-backend.taeger.online';
const API_KEY = 'key'; // Setze deinen API-Schlüssel hier ein

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': API_KEY,
  },
});

// Interface für die Tags
export interface TagResponse {
  tags: string[];
}

// Interface für die Zutaten (Ingredients)
export interface IngredientResponse {
  bottles: {
    id: number;
    name: string;
    alcoholic: boolean;
    calibration_factor: number;
    current_amount: number;
    port: number;
    created_at: string;
    updated_at: string;
  }[];
}

// Interface für Cocktail Zutaten
export interface Ingredient {
  id: number;
  name: string;
  amount: number | null;
  order_number: number;
  alcoholic: boolean;
  cocktail_id: number;
  created_at: string;
  updated_at: string;
}

// Interface für Cocktails
export interface Cocktail {
  id: number;
  name: string;
  description: string;
  alcohol_content: number;
  sweetness: number;
  tags: string;
  ingredients: Ingredient[];
  is_alcoholic: boolean;
  is_doable: boolean;
  availability: boolean;
  created_at: string;
  updated_at: string;
}

// API-Funktion zum Laden der Tags
export const fetchTags = async (): Promise<TagResponse> => {
  const response = await apiClient.get<TagResponse>('/cocktails/tags');
  return response.data;
};

// API-Funktion zum Laden der Zutaten (Ingredients)
export const fetchIngredients = async (): Promise<IngredientResponse> => {
  const response = await apiClient.get<IngredientResponse>('/bottles/all');
  return response.data;
};

// API-Funktion zum Laden der Cocktails
export const fetchCocktails = async (): Promise<Cocktail[]> => {
  const response = await apiClient.get<Cocktail[]>('/cocktails/doable');
  return response.data;
};
