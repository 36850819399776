import { useState, useEffect } from 'react';
import { fetchIngredients, IngredientResponse } from '../api/cocktailApi';

const useIngredients = () => {
  const [ingredients, setIngredients] = useState<IngredientResponse['bottles']>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadIngredients = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchIngredients();
        setIngredients(data.bottles);
      } catch (err) {
        setError('Fehler beim Laden der Zutaten');
      } finally {
        setLoading(false);
      }
    };

    loadIngredients();
  }, []);

  return { ingredients, loading, error };
};

export default useIngredients;
