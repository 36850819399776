import React, { useState, useEffect } from 'react';
import useCocktails from '../hooks/useCocktails';
import useIngredients from '../hooks/useIngredients';
import useTags from '../hooks/useTags';
import SearchBar from '../components/SearchBar';
import CocktailGrid from '../components/CocktailGrid';

const HomePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
  const [merkenList, setMerkenList] = useState<number[]>([]); // Merken IDs
  const [isTagsOpen, setIsTagsOpen] = useState<boolean>(false); // Tags-Filter standardmäßig eingeklappt
  const [isIngredientsOpen, setIsIngredientsOpen] = useState<boolean>(false); // Zutaten-Filter standardmäßig eingeklappt
  const [isMerklisteOpen, setIsMerklisteOpen] = useState<boolean>(false); // Merkliste Reiter

  const { cocktails, loading: cocktailsLoading, error: cocktailsError } = useCocktails();
  const { ingredients, loading: ingredientsLoading, error: ingredientsError } = useIngredients();
  const { tags, loading: tagsLoading, error: tagsError } = useTags();

  // Lade gemerkte Cocktails aus dem localStorage
  useEffect(() => {
    const storedMerkenList = localStorage.getItem('cocktail-merken');
    if (storedMerkenList) {
      setMerkenList(JSON.parse(storedMerkenList));
    }
  }, []);

  // Speichere die gemerkten Cocktails im localStorage
  useEffect(() => {
    localStorage.setItem('cocktail-merken', JSON.stringify(merkenList));
  }, [merkenList]);

  // Alle Zutaten standardmäßig auswählen
  useEffect(() => {
    if (!ingredientsLoading && !ingredientsError) {
      setSelectedIngredients(ingredients.map(ingredient => ingredient.name));
    }
  }, [ingredientsLoading, ingredientsError, ingredients]);

  // "alkoholfrei" immer als erstes Tag anzeigen
  const sortedTags = tags ? ['alkoholfrei', ...tags.filter(tag => tag !== 'alkoholfrei')] : [];

  // Merken-Handling
  const toggleMerken = (cocktailId: number) => {
    setMerkenList(prev =>
      prev.includes(cocktailId) ? prev.filter(id => id !== cocktailId) : [...prev, cocktailId]
    );
  };

  // Gemerkte Cocktails filtern
  const gemerkteCocktails = cocktails.filter(cocktail => merkenList.includes(cocktail.id));

  // Filtere Cocktails, die nicht in der Merkliste sind, wenn die Merkliste ausgeklappt ist
  const cocktailsToDisplay = isMerklisteOpen
    ? cocktails.filter(cocktail => !merkenList.includes(cocktail.id))
    : cocktails;

  // Funktion zum Filtern nach Suchbegriff, Zutaten und Tags
  const matchesSearchTerm = (cocktail: any) => {
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term =>
      cocktail.name.toLowerCase().includes(term) ||
      cocktail.tags.toLowerCase().includes(term) ||
      cocktail.description.toLowerCase().includes(term) ||
      cocktail.ingredients.some((ingredient: any) =>
        ingredient.name.toLowerCase().includes(term)
      )
    );
  };

  const filteredCocktails = cocktailsToDisplay
    .filter(
      cocktail =>
        cocktail.ingredients
          .filter((ingredient: any) => ingredient.order_number >= 1 && ingredient.order_number <= 99)
          .every((ingredient: any) => selectedIngredients.includes(ingredient.name)) &&
        selectedTags.every(tag => cocktail.tags.includes(tag)) &&
        matchesSearchTerm(cocktail)
    )
    .sort((a, b) => a.name.localeCompare(b.name)); // Sortiere die Cocktails alphabetisch

  // Scroll-to-top Funktion
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-4xl font-bold text-center mt-4 mb-2">Cocktailkarte</h1>
      
      {/* SearchBar über die ganze Breite */}
      <div className="w-full mb-4">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {/* Tags Filter */}
      <div className="my-4">
        <button className="w-full text-left font-bold text-xl" onClick={() => setIsTagsOpen(!isTagsOpen)}>
          Filtern nach Tags {isTagsOpen ? '▲' : '▼'}
        </button>
        {isTagsOpen && (
          <div className="mt-2">
            {tagsLoading ? (
              <p>Lade Tags...</p>
            ) : tagsError ? (
              <p>Fehler: {tagsError}</p>
            ) : (
              <div className="flex flex-wrap gap-2">
                {sortedTags.map(tag => (
                  <button
                    key={tag}
                    className={`px-4 py-2 rounded-full ${selectedTags.includes(tag) ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    onClick={() =>
                      setSelectedTags(prev =>
                        prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
                      )
                    }
                  >
                    {tag}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Ingredients Filter */}
      <div className="my-4">
        <button className="w-full text-left font-bold text-xl" onClick={() => setIsIngredientsOpen(!isIngredientsOpen)}>
          Filtern nach Zutaten {isIngredientsOpen ? '▲' : '▼'}
        </button>
        {isIngredientsOpen && (
          <div className="mt-2">
            {ingredientsLoading ? (
              <p>Lade Zutaten...</p>
            ) : ingredientsError ? (
              <p>Fehler: {ingredientsError}</p>
            ) : (
              <div className="flex flex-wrap gap-2">
                {ingredients.map(ingredient => (
                  <button
                    key={ingredient.name}
                    className={`px-4 py-2 rounded-full ${selectedIngredients.includes(ingredient.name) ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    onClick={() =>
                      setSelectedIngredients(prev =>
                        prev.includes(ingredient.name) ? prev.filter(i => i !== ingredient.name) : [...prev, ingredient.name]
                      )
                    }
                  >
                    {ingredient.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Merkliste Reiter */}
      <div className="my-4">
        <button className="w-full text-left font-bold text-xl" onClick={() => setIsMerklisteOpen(!isMerklisteOpen)}>
          Merkliste {isMerklisteOpen ? '▲' : '▼'}
        </button>
        {isMerklisteOpen && (
          <div className="mt-4">
            {merkenList.length === 0 ? (
              <p>Du hast noch keine Cocktails gemerkt.</p>
            ) : (
              <CocktailGrid
                cocktails={gemerkteCocktails}
                searchTerm={searchTerm}
                selectedTags={selectedTags}
                selectedIngredients={selectedIngredients}
                toggleMerken={toggleMerken}
                merkenList={merkenList}
              />
            )}
          </div>
        )}
      </div>

      {/* Doppelte Linie als Trennung */}
      {isMerklisteOpen && (
        <div className="my-2">
          <hr className="border-t-2 border-gray-300" />
          <hr className="border-t-2 border-gray-300 mt-1" />
        </div>
      )}

      {/* Cocktail Grid (unter der Merkliste) */}
      <div className="my-4">
        {cocktailsLoading ? (
          <p>Lade Cocktails...</p>
        ) : cocktailsError ? (
          <p>Fehler: {cocktailsError}</p>
        ) : (
          <CocktailGrid
            cocktails={filteredCocktails} // Gefilterte und sortierte Cocktails anzeigen
            searchTerm={searchTerm}
            selectedTags={selectedTags}
            selectedIngredients={selectedIngredients}
            toggleMerken={toggleMerken}
            merkenList={merkenList}
          />
        )}
      </div>

      {/* Scroll-to-Top Button (immer sichtbar, rund, und mit etwas dunklerem Hintergrund) */}
      <button
        onClick={scrollToTop}
        className="fixed bottom-10 right-10 bg-gray-400 text-white p-3 rounded-full shadow-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-600"
        style={{ opacity: 0.8 }}
      >
        ↑
      </button>
    </div>
  );
};

export default HomePage;
