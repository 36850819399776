import React, { useState, useEffect } from 'react';

interface CocktailCardProps {
  cocktail: {
    id: number;
    name: string;
    description: string;
    is_alcoholic: boolean;
    availability: boolean;
  };
  isMerken: boolean;
  toggleMerken?: (cocktailId: number) => void;
}

const CocktailCard: React.FC<CocktailCardProps> = ({ cocktail, isMerken, toggleMerken }) => {
  const [imageSrcSet, setImageSrcSet] = useState('');
  const [imageFallback, setImageFallback] = useState('');

  useEffect(() => {
    const imageName = cocktail.name
      .toLowerCase()
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '_')
      .replace(/_+/g, '_');

    const possibleExtensions = ['jpeg', 'jpg', 'JPG', 'JPEG'];

    const findImagePath = async () => {
      let imageFound = false;
      for (let ext of possibleExtensions) {
        try {
          // Lade das Bild im JPEG-Format, da WebP erst beim Build erzeugt wird
          const image = await import(`../images/${imageName}.${ext}`);
          setImageFallback(image.default);  // Setze JPEG als Fallback
          imageFound = true;
          break;
        } catch (error) {
          console.log(`Bild nicht gefunden: ${imageName}.${ext}`);
        }
      }

      if (!imageFound) {
        // Fallback auf ein Backup-Bild, falls kein Bild gefunden wird
        const fallback = await import('../images/backup.jpg');
        setImageFallback(fallback.default);
      }
    };

    findImagePath();
  }, [cocktail.name]);

  return (
    <div
      className={`relative border rounded-lg overflow-hidden ${
        cocktail.availability ? 'cursor-pointer' : 'cursor-not-allowed'
      } ${!cocktail.availability ? 'opacity-50' : ''} flex flex-row-reverse h-auto`}
    >
      <div className="w-1/3 flex-shrink-0 flex flex-col items-center justify-center">
        <div className="text-center mb-2 text-xs font-bold bg-gray-200 p-2 rounded-lg">
          {cocktail.is_alcoholic ? 'alkoholisch' : 'alkoholfrei'}
        </div>
        <div className="aspect-square m-1">
          <picture>
            {/* WebP-Source wird erst im Build-Prozess generiert */}
            <source srcSet={`${imageFallback}`} type="image/webp" />
            <img
              src={imageFallback} // Nutze JPEG als Fallback für den Entwicklungsprozess
              alt={cocktail.name}
              className="object-cover w-full h-full rounded-lg"
            />
          </picture>
        </div>
      </div>

      <div className="p-4 px-2 flex-grow flex flex-col justify-center">
        <h3 className="text-lg font-bold">{cocktail.name}</h3>
        <p className="text-gray-700 text-left">{cocktail.description}</p>

        {toggleMerken && (
          <button
            onClick={() => toggleMerken(cocktail.id)}
            className={`mt-2 px-4 py-2 rounded-full transition-all ${
              isMerken ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-800'
            } hover:bg-green-400`}
          >
            {isMerken ? 'Cocktail gemerkt! 🍹' : 'Für später merken ⭐'}
          </button>
        )}
      </div>
    </div>
  );
};

export default CocktailCard;
