import React from 'react';
import CocktailCard from './CocktailCard';

interface CocktailGridProps {
  cocktails: any[]; // Die Liste der Cocktails
  searchTerm: string;
  selectedTags: string[];
  selectedIngredients: string[];
  toggleMerken?: (cocktailId: number) => void; // Optionaler Prop für das Merken
  merkenList?: number[]; // Optionaler Prop für die gemerkten Cocktails
}

const CocktailGrid: React.FC<CocktailGridProps> = ({
  cocktails,
  searchTerm,
  selectedTags,
  selectedIngredients,
  toggleMerken, // Füge toggleMerken hinzu
  merkenList = [], // Standardwert für merkenList (leeres Array)
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {cocktails.map(cocktail => (
        <CocktailCard
          key={cocktail.id}
          cocktail={cocktail}
          isMerken={merkenList.includes(cocktail.id)} // Zeige an, ob der Cocktail gemerkt ist
          toggleMerken={toggleMerken} // Funktion zum Hinzufügen/Entfernen von der Merkliste
        />
      ))}
    </div>
  );
};

export default CocktailGrid;
