import { useState, useEffect } from 'react';
import { fetchCocktails, Cocktail } from '../api/cocktailApi';

const useCocktails = () => {
  const [cocktails, setCocktails] = useState<Cocktail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCocktails = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchCocktails();
        setCocktails(data);
      } catch (err) {
        setError('Fehler beim Laden der Cocktails');
      } finally {
        setLoading(false);
      }
    };

    loadCocktails();
  }, []);

  return { cocktails, loading, error };
};

export default useCocktails;
