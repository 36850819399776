import React from 'react';

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="top-0 w-full bg-white p-2 shadow flex items-center">
      {/* Suchleiste */}
      <input
        type="text"
        className="flex-grow p-2 border border-gray-300 rounded"
        placeholder="Suche nach Cocktails, Zutaten, etc."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
